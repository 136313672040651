@font-face {
  font-family: "Nunito";
  src: local("Nunito"), url("../fonts/Nunito-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Open Sans";
  src: local("Open Sans"),
    url("../fonts/OpenSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "CIBFont Sans";
  src: local("CIBFont Sans"),
    url("../fonts/CIBFontSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "fontello";
  src: url("../fonts/fontello.eot?0000");
  src: url("../fonts/fontello.eot?0000#iefix") format("embedded-opentype"),
    url("../fonts/fontello.woff2?0000") format("woff2"),
    url("../fonts/fontello.woff?0000") format("woff"),
    url("../fonts/fontello.ttf?0000") format("truetype"),
    url("../fonts/fontello.svg?0000#fontello") format("svg");
  font-weight: normal;
  font-style: normal;
}

input[type="password"] {
  font-family: fontello,sans-serif;
  font-style: normal;
  font-weight: normal;
  speak: none;

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */

  /* add spacing to better separate each image */
  letter-spacing: 2px;
}

input[type="number"] {
  font-family: fontello,sans-serif;
  -webkit-text-security: disc;
  -moz-webkit-text-security: disc;
  -moz-text-security: disc;
}

input[type="checkbox"] {
  accent-color: #2C2A29;
}

body {
  background-color: #f7f7f7;
  height: 100%;
  width: 100%;
}

a {
  text-decoration: none;
}

a:visited {
  color: #2C2A29;
}

a:active {
  color: #2C2A29;
}

a:link {
  color: #2C2A29;
}
